/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * editor
 * @default
 *
 */

.editor {
  background: white;
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
}
