/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * App
 * @css
 *
 */

/*
  Official CLOUDPILOTS colors

  red: #EA4335
  green: #34A853
  yellow: #FBBC05
  blue: #4285F4

*/

a {
  text-decoration: none;
  /* color: #4285f4; */
}

a:hover {
  background-color: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .cpl-logo {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 3px;
} */

#main-menu-logo .cpl-logo {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 3px;
  margin-left: 8px;
}

#CPL-Full-Logo {
  display: inline-block;
  height: 60px;
  width: 130px;
  opacity: 0.6;
}

#CPL-Full-Logo.fixed {
  position: fixed;
  left: 50%;
  bottom: 30px;
  margin-left: -65px;
  opacity: 0.6;
}

.cpl-logo.fixed {
  position: fixed;
  left: 50%;
  bottom: 30px;
  margin-left: -30px;
  opacity: 0.6;
  height: 60px;
  width: 60px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cpl-logo.loading {
  animation: spin 2s infinite linear;
}

.hide {
  display: 'none';
}

.btn-login {
  color: white;
  padding: 8px 16px;
  min-width: 88px;
  font-size: 14px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.4em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  text-decoration: none;
  justify-content: center;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn-login.btn-login-single {
  color: black;
}
