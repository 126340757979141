/**
 * Copyright 2018, CLOUDPILOTS Software & Consulting GmbH
 *
 * GoogleButton
 * @css
 *
 */

#google-signIn {
  width: 100%;
}

#google-signIn div.abcRioButton {
  width: 100% !important;
}
