/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * AutoCompleteSelect
 * @default
 *
 */

.container-autocomplete {
  max-height: 130px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
  position: absolute;
  width: 100%;
}
