/**
 * Copyright 2019, CLOUDPILOTS Software & Consulting GmbH
 *
 * Login
 * @css
 *
 */

#login {
  height: 100vh;
}

.loginCard {
  /* border-top: 7px solid #b0000c;
  border-bottom: 7px solid #b0000c; */
  border-radius: 7px !important;
  padding: 2rem 2rem 3rem;
  text-align: center;
  color: white;
}

.loginCard.animated {
  animation-delay: 0.4s;
  animation-duration: 0.4s;
}

.loginCard button {
  cursor: pointer;
}

.loginCard #CPL-Logo {
  margin-bottom: 10px;
  animation-delay: 0.5s;
  animation-duration: 0.4s;
  width: 60px;
  height: 60px;
}

#CPL-Full-Logo.fixed.animated {
  animation-delay: 0.4s;
  animation-duration: 0.4s;
}

@keyframes expand {
  from {
    max-height: 0;
  }
  to {
    max-height: 200px;
  }
}

/* .loginCard .loginHeader .animated {
}

.loginCard .loginContent {
} */

.welcome.animated {
  animation-delay: 10s;
}
